import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

import InfoBlock from '@archinsurance-viki/property-jslib/src/components/blocks/InfoBlock';
import { DataInfoBlock } from '@archinsurance-viki/property-jslib/src/components/blocks/DataInfoBlock';
import { RowInfoBlock } from '@archinsurance-viki/property-jslib/src/components/blocks';

import {
    ADDITIONAL_NAMED_INSUREDS_DEFS,
    BUSINESS_SEGMENT_TO_SUBDIVISION_MAPPING,
    SUBMISSION_STATUS,
    SUBMISSION_STATUS_MAP,
    SUPPLEMENTAL_DEFINITIONS,
} from '../../constants/Submission';
import { INFO_PANEL_TYPES, SUBMISSION_INFOBLOCKS } from '../../constants/SubmissionInfoblocks';

import { processColumnsForInfoBlocks } from '@archinsurance-viki/property-jslib/src/utils/tables/columns';
import { COMBINED_TABLE_GLOSSARY } from '../../constants/CombinedTableGlossary';
import { calculateReadonlyFlag } from '@archinsurance-viki/property-jslib/src/utils/form-helpers';
import { submissionIsEditable, transactionIsPremiumBearing } from '../submissionlog/Helpers';
import { NOTE_TYPE_GENERAL_NOTE } from '../../constants/NoteConstants';
import { blockPropTypes, noteBlockPropType, SubmissionDataType } from '../../ts-types/DataTypes';
import { NoteDataType } from '@archinsurance-viki/property-jslib/src/ts-types/NoteTypes';
import { useAppContext } from '../../hooks/context';
import { useAppSelector } from '../../hooks/redux';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import { FormDateInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormDateInput';
import { TwBreak } from '../pricing-page/PricingGrid';
import { useSaveSubmissionOverviewDataMutation, useSubmissionOverviewDataQuery } from '../../services/endpoints/submission';
import { TenantGlossaryItemType } from '../../ts-types/ApiTypes';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useSubmissionId, useSubmissionIsEditable } from '../../hooks/submissions';
import { FormValuesFromType, YES_OR_NO_CHOICES, getFormDefaultsFromGlossary, getFormValuesForSubmit } from '../../features/coverage-terms/utils/form';
import { Choice } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import BasicInput from '@archinsurance-viki/property-jslib/src/components/inputs/v2/BasicInput';
import { CHOOSE_A_VALUE, MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { isLayeredRatio } from '../../utils/overview-helpers';
import { BlurSubmitFormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/BlurSubmitFormInput';
import { useChangeEffect } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { SubmissionOverviewGlossaryResult, SubmissionOverviewResult } from '../../ts-types/ApiTypes';
import { TextArea } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/TextArea';
import { RAE_SITE_ID } from '../../constants/SiteConstants';
import { useGetUnderwritersQuery, useGetUnderwriterDetailQuery } from '@archinsurance-viki/property-jslib/src/services/referenceDataApi';
import { selectFeatureFlags } from '../../selectors/environment';

type propTypes = {
    submissionGlossary: SubmissionOverviewGlossaryResult;
    oldGlossaryData: Record<string, TenantGlossaryItemType>;
    currentNotes: Record<string, any>;
    currentRenewalNotes: Record<string, any>;
    onPopNoteModal: (data: Record<string, any>) => void;
    onAddNote: (note: NoteDataType) => void;
    onSaveSubmissionData: (change: Record<string, any>) => void;
    onAddOrEditAdditionalNamedInsured: (ani: Record<string, any>, data: Record<string, any>) => void;
    // NOTE: submission may differ from the one on appContext (see OverviewContainer)
    currentSubmission: SubmissionDataType;
    currentSubmissionId: number;
    children?: React.ReactNode;
};

const selectChoices = <Value,>(choices: [Value, string][]) => {
    return choices.map(([value, display]) => ({ value, display }));
};

const selectActiveChoices = <Value,>(choices: [Value, string, boolean][]) => {
    return choices.filter(([_v, _d, is_inactive]) => !is_inactive).map(([value, display]) => ({ value, display }));
};

const sortChoices = <Value,>(choices: Choice<Value>[]) => {
    return [...choices].sort(({ value: _va, display: displayA }, { value: _vb, display: displayB }) => {
        if (displayA < displayB) {
            return -1;
        } else if (displayA > displayB) {
            return 1;
        } else {
            return 0;
        }
    });
};

const BADGE_CLASSNAME_MAP = {
    SATISFACTORY: 'badge-success',
    FAIR: 'badge-yellow',
    UNSATISFACTORY: 'badge-danger',
    NA: 'badge-grey',
};

const OverviewApp = (props: propTypes) => {
    const currentSubmissionId = useSubmissionId();
    const { children, currentSubmission, onAddOrEditAdditionalNamedInsured, submissionGlossary, currentNotes, currentRenewalNotes } = props;
    const { currentAccountId, currentQuote, currentTransaction } = useAppContext();
    const { policy_coverage: currentQuotePolicyCoverage, final_premium: currentQuoteFinalPremium } = currentQuote ?? {};
    const featureFlags = useAppSelector(state => selectFeatureFlags(state));
    const ENV = useAppSelector(state => state.global.ENV);
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const UNDERWRITER_CHOICES = useAppSelector(state => sortChoices(selectActiveChoices(state.global.CONSTANTS.UNDERWRITERS)));

    const OVERVIEW_GROUPS = useAppSelector(state => state.global.CONSTANTS.SUBMISSION_OVERVIEW_GROUPS);

    const { data: overviewData } = useSubmissionOverviewDataQuery({ submissionId: currentSubmissionId });
    const accountBrokerAddress = [overviewData?.account_broker_address1, overviewData?.account_broker_address2];
    const licensedBrokerAddress = [overviewData?.licensed_broker_address1, overviewData?.licensed_broker_address2];

    const [triggerSave] = useSaveSubmissionOverviewDataMutation();
    const readonly = !useSubmissionIsEditable();
    const isBound = overviewData?.status === SUBMISSION_STATUS.BOUND;
    const isRae = ENV.SITE.id === RAE_SITE_ID;

    // TODO: Use same $ formatting as policy terms page to keep code consistent
    const formDefaultValues = getFormDefaultsFromGlossary<FormValuesFromType<SubmissionOverviewResult>>(overviewData, submissionGlossary, true);
    const formMethods = useForm({
        defaultValues: formDefaultValues,
    });
    const {
        clearErrors,
        control,
        formState: { dirtyFields, errors },
        resetField,
        setError,
    } = formMethods;

    const office = useWatch({ control, name: 'brokerage_office_id' }) as number;
    const PRODUCING_AGENTS_CHOICES = useAppSelector(state => {
        const agentsByOffice = state.global.CONSTANTS.PRODUCING_AGENTS_BY_OFFICE;
        if (!office || !agentsByOffice?.[office]) {
            return [];
        }
        return sortChoices(selectChoices(agentsByOffice[office]));
    });
    const LICENSED_BROKER_CHOICES = useAppSelector(state => {
        const licensedBrokersByOffice = state.global.CONSTANTS.LICENSED_BROKERS_BY_OFFICE;
        if (!office || !licensedBrokersByOffice?.[office]) {
            return [];
        }
        return sortChoices(selectChoices(licensedBrokersByOffice[office]));
    });

    const homeState = useWatch({ control, name: 'home_state' });

    const selectedUnderwriterId = useWatch({ control, name: 'underwriter_id' });
    const [uwID, uwName, uwInactive] = CONSTANTS.UNDERWRITERS.find(([value]) => `${value}` === `${selectedUnderwriterId}`) ?? [];

    const selectedBusinessSegment = useWatch({ control, name: 'business_segment' }) as keyof typeof BUSINESS_SEGMENT_TO_SUBDIVISION_MAPPING;
    const selectedArchUnderwriterId = useWatch({ control, name: 'arch_underwriter_id' });
    const { data: aUW } = useGetUnderwriterDetailQuery(selectedArchUnderwriterId, { skip: !featureFlags.enable_uw_list_ff || !selectedArchUnderwriterId });

    const underwriterSubdivision =
        currentSubmission?.arch_sub_division ??
        BUSINESS_SEGMENT_TO_SUBDIVISION_MAPPING[selectedBusinessSegment] ??
        BUSINESS_SEGMENT_TO_SUBDIVISION_MAPPING.ES_LINES;

    const { referenceDataUnderwriterChoices } = useGetUnderwritersQuery(underwriterSubdivision, {
        skip: !featureFlags.enable_uw_list_ff || !currentSubmission,
        selectFromResult: result => {
            const choices =
                _.map(result?.data, underwriter => ({
                    value: underwriter.userNumber,
                    display: underwriter.name,
                    isInactive: !underwriter.isActive,
                })) ?? [];
            return { referenceDataUnderwriterChoices: choices };
        },
    });

    const useAssignedClaimsAdjuster = useWatch({ control, name: 'use_assigned_claims_adjuster' });

    const dataCompletenessRating = CONSTANTS.ARCH_DATA_COMPLETENESS_INDEXES.find(
        ({ value }) => value === overviewData?.primary_quote_data_completeness_index_rating
    )?.display;
    const buildingValueRating = CONSTANTS.ARCH_DATA_COMPLETENESS_INDEXES.find(
        ({ value }) => value === overviewData?.primary_quote_itv_account_quality_index_rating
    )?.display;

    // Reset override default values when changed via input form or via external event
    // Ensure that we only reset fields that have changed to avoid wiping out user input
    // Note: we have extra isDirty checks in here because the final premium can change our default value for inspection fees
    useChangeEffect(formDefaultValues, (nextDefaults, prevDefaults) => {
        Object.keys(nextDefaults).forEach((key: keyof typeof formDefaultValues) => {
            if (!prevDefaults || prevDefaults[key] !== nextDefaults[key]) {
                resetField(key, { defaultValue: nextDefaults[key] });
            }
        });
    });

    const onHandleSaveSubmission = (field: string, value: string | number | null) => {
        let change = {
            request: {
                data: { [field]: value },
                pks: [currentSubmissionId],
            },
        };

        props.onSaveSubmissionData(change);
    };

    const handleAddOrEditNote = (rowData: Record<string, unknown>, blockProps: noteBlockPropType) => {
        props.onPopNoteModal({
            saveParams: {
                note_type: blockProps.noteType,
                submission_id: currentSubmissionId,
            },
            idKey: currentAccountId || currentSubmissionId,
            note: rowData,
            includeSubject: blockProps.noteType === NOTE_TYPE_GENERAL_NOTE,
            readOnly: (isRae && isBound) || (blockProps.readonly && !!rowData.id),
            noDismiss: true,
        });
    };

    let defs = processColumnsForInfoBlocks(
        { ...SUPPLEMENTAL_DEFINITIONS, ...ADDITIONAL_NAMED_INSUREDS_DEFS },
        _.merge(props.oldGlossaryData, COMBINED_TABLE_GLOSSARY.accounttransactiondatasqlview),
        CONSTANTS
    );

    let helperFunctions = {
        handleAddInsured: onAddOrEditAdditionalNamedInsured,
        handleEditInsured: onAddOrEditAdditionalNamedInsured,
        handleAddNote: handleAddOrEditNote,
        handleEditNote: handleAddOrEditNote,
        handleSaveSubmission: onHandleSaveSubmission,
    };

    const isReadonly = !submissionIsEditable(currentSubmission) || !transactionIsPremiumBearing(currentTransaction);
    const renderBlock = (block: blockPropTypes | noteBlockPropType) => {
        let parentProps;
        if (block.type === 'RENEWAL_NOTE') {
            parentProps = { ...props, currentRenewalNotes, currentQuote };
        } else {
            parentProps = { ...props, currentNotes, currentQuote };
        }

        if (currentSubmission.status === 'BOUND' && block.key === 'status') {
            return false;
        }
        if (currentSubmission.status !== 'BOUND' && block.key === 'bound_status') {
            return false;
        }
        if (!overviewData?.is_renewal && block.type === 'RENEWAL_NOTE') {
            return false;
        }

        // VIKI-6810 'Exp Prem' and 'Exp Carrier' fields should be editable after account is bound
        // VENT-11385/VENT-11674 - Claims Adjuster fields should be editable after account is bound
        if ((block.header === 'Policy Information' || block.header === 'Assigned Claims Adjuster') && currentSubmission.status === 'BOUND' && !isRae) {
            block.readonly = () => false;
        }

        const readonly = calculateReadonlyFlag(block.readonly, isReadonly, currentSubmission, null, block.header);

        if (block.type === 'NOTE' && (block as noteBlockPropType).noteType !== NOTE_TYPE_GENERAL_NOTE) {
            return (
                <RowInfoBlock
                    helperKey="Note"
                    {...(block as noteBlockPropType)}
                    readonly={(isRae && isBound) || readonly}
                    glossary={defs}
                    parentProps={parentProps}
                    helperFunctions={helperFunctions}
                />
            );
        } else {
            return (
                <InfoBlock
                    ENV={ENV}
                    helperKey={block.type === 'NOTE' ? 'Note' : block.type === 'ADDITIONAL_NAME_INSUREDS' ? 'Insured' : 'Submission'}
                    {...block}
                    locationContext={INFO_PANEL_TYPES.OVERVIEW}
                    readonly={(isRae && isBound) || readonly}
                    glossary={defs}
                    parentProps={parentProps}
                    helperFunctions={helperFunctions}
                />
            );
        }
    };

    if (!currentSubmission || !overviewData) {
        return null;
    }

    const submit = formMethods.handleSubmit(async data => {
        const submitData = getFormValuesForSubmit<SubmissionOverviewResult>(data, submissionGlossary, dirtyFields);

        if (Object.keys(submitData).length === 0) {
            return;
        }

        try {
            clearErrors();
            await triggerSave({ submissionId: currentSubmissionId, data: submitData }).unwrap();
        } catch (error) {
            Object.keys(error?.data?.[0]?.field_errors ?? []).forEach((key: keyof SubmissionOverviewGlossaryResult) => {
                setError(key, { type: 'custom', message: error.data[0].field_errors[key].join(' ') as string }, { shouldFocus: true });
            });
        }
    });

    const activeGroups: Record<string, boolean> = {};
    Object.keys(OVERVIEW_GROUPS).forEach(key => {
        const dataKey = `group_${key.toLocaleLowerCase()}`;
        activeGroups[key] = submissionGlossary?.[dataKey].active ?? false;
    });

    const ventusUnderwriterChoices = uwInactive ? [{ value: uwID, display: uwName }, ...UNDERWRITER_CHOICES] : UNDERWRITER_CHOICES;
    const archUnderwriterChoices: Choice<string>[] = _.map(
        _.sortBy(
            aUW?.isActive === false
                ? [{ value: aUW.userNumber, display: aUW.name, isInactive: !aUW.isActive }, ...referenceDataUnderwriterChoices]
                : referenceDataUnderwriterChoices,
            'display'
        ),
        underwriter => ({
            value: underwriter.value,
            display: underwriter.isInactive ? `(Inactive) ${underwriter.display}` : underwriter.display,
        })
    );

    return (
        <div className="info-block-wrapper pad-1" key="overpanel">
            <div className="grid-layout overview-grid grid-row-min-10">
                <FormProvider {...formMethods}>
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.GENERAL}
                        active={activeGroups.GENERAL}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-gap-1"
                        glossary={submissionGlossary}
                        readonly={readonly}
                    >
                        <FormSelect
                            name="status"
                            selections={Object.entries(SUBMISSION_STATUS_MAP).map(([key, display]) => ({ value: key, display }))}
                            disabled
                        />
                        <FormInput name="renewal_status" disabled />

                        {featureFlags.enable_uw_list_ff && !currentSubmission.underwriter_id ? (
                            <FormSelect name="arch_underwriter_id" selections={archUnderwriterChoices} onSubmit={submit} nullLabel={CHOOSE_A_VALUE} optional />
                        ) : (
                            <FormSelect name="underwriter_id" selections={ventusUnderwriterChoices} onSubmit={submit} nullLabel={CHOOSE_A_VALUE} optional />
                        )}
                        <TwBreak />
                        <FormDateInput name="date_received" disabled />
                        <FormDateInput name="primary_quote_date_sent" disabled />
                        {!isBound && (
                            <>
                                <FormDateInput name="target_effective_date" onSubmit={submit} onReset={() => resetField('target_effective_date')} />
                                <FormDateInput name="target_expiration_date" onSubmit={submit} onReset={() => resetField('target_expiration_date')} />
                            </>
                        )}
                        <FormDateInput name="target_quote_date" onSubmit={submit} onReset={() => resetField('target_quote_date')} />
                        <BlurSubmitFormInput name="target_premium" onSubmit={submit} onReset={() => resetField('target_premium')} />
                        {isBound && (
                            <>
                                <FormDateInput name="effective_date" />
                                <FormDateInput name="expiration_date" />
                                <FormDateInput name="date_bound" />
                            </>
                        )}
                    </DataInfoBlock>
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.STRATEGY}
                        active={activeGroups.STRATEGY}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-auto-rows-min tw-gap-1"
                        glossary={submissionGlossary}
                        readonly={readonly}
                    >
                        <FormSelect name="business_segment" selections={CONSTANTS.BUSINESS_SEGMENTS} disabled />
                        <FormSelect name="new_business_segment" selections={CONSTANTS.NEW_BUSINESS_SEGMENTS} disabled />
                        <FormSelect name="business_strategy" selections={CONSTANTS.BUSINESS_STRATEGIES} disabled />
                        <FormInput name="carrier_pools_display" disabled />
                    </DataInfoBlock>
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.BROKER_INFORMATION}
                        active={activeGroups.BROKER_INFORMATION}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-auto-rows-min tw-gap-1"
                        glossary={submissionGlossary}
                        readonly={readonly}
                    >
                        <FormInput name="parent_brokerage_company_name" disabled />
                        <FormInput name="brokerage_company_name" disabled />
                        <FormInput name="brokerage_office_location" disabled />
                        <FormSelect name="account_broker_id" selections={PRODUCING_AGENTS_CHOICES} onSubmit={submit} nullLabel={CHOOSE_A_VALUE} optional />
                        {submissionGlossary.account_broker_address1.active && (
                            <>
                                <label>Acct Broker Address</label>
                                <TextArea className="[&>textarea]:tw-text-right" value={accountBrokerAddress?.join('\n')} rows={3} cols={20} disabled />
                            </>
                        )}
                        <FormInput name="account_broker_email" disabled />
                        <FormInput name="account_broker_phone_number" disabled />
                        <FormInput name="office_arch_id" disabled />
                        <FormSelect name="licensed_broker_id" selections={LICENSED_BROKER_CHOICES} onSubmit={submit} nullLabel={CHOOSE_A_VALUE} optional />
                        <FormInput name="licensed_broker_arch_id" disabled />
                        {submissionGlossary.licensed_broker_address1.active && (
                            <>
                                <label>Lic Broker Address</label>
                                <TextArea className="[&>textarea]:tw-text-right" value={licensedBrokerAddress?.join('\n')} rows={3} cols={20} disabled />
                            </>
                        )}
                        <FormSelect name="support_contact_id" selections={PRODUCING_AGENTS_CHOICES} onSubmit={submit} nullLabel={CHOOSE_A_VALUE} optional />
                        <FormInput name="support_contact_email" disabled />
                        <FormInput name="support_contact_phone_number" disabled />
                        <FormInput name="brokerage_office_fein" disabled />
                        {homeState === 'NJ' && <BlurSubmitFormInput name="sla_number" onSubmit={submit} onReset={() => resetField('sla_number')} />}
                    </DataInfoBlock>
                    {activeGroups.OCCUPANCY_NOTES && renderBlock(SUBMISSION_INFOBLOCKS.occupancy_notes)}
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.POLICY_INFORMATION}
                        active={activeGroups.POLICY_INFORMATION}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-auto-rows-min tw-gap-1"
                        glossary={submissionGlossary}
                        readonly={readonly}
                    >
                        <FormInput name="renewal_of_id" disabled />
                        <FormInput name="rewrite_of_policy_number" disabled />
                        <FormInput name="policy_number" disabled />
                        <FormInput name="policy_issuance_date" disabled />
                        <BlurSubmitFormInput
                            name="insured_expiring_premium"
                            onSubmit={submit}
                            onReset={() => resetField('insured_expiring_premium')}
                            disabled={isRae && isBound}
                        />
                        <BlurSubmitFormInput
                            name="insured_expiring_carrier"
                            onSubmit={submit}
                            onReset={() => resetField('insured_expiring_carrier')}
                            disabled={isRae && isBound}
                        />
                    </DataInfoBlock>
                    {activeGroups.UNDERWRITING_RATIONALE && renderBlock(SUBMISSION_INFOBLOCKS.underwriter_notes)}
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.LOSS_HISTORY}
                        active={activeGroups.LOSS_HISTORY}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-auto-rows-min tw-gap-1"
                        glossary={submissionGlossary}
                        readonly={readonly}
                    >
                        <BlurSubmitFormInput name="insured_loss_ratio_years" onSubmit={submit} onReset={() => resetField('insured_loss_ratio_years')} />
                        <BlurSubmitFormInput name="insured_loss_history" onSubmit={submit} onReset={() => resetField('insured_loss_history')} />
                        <FormSelect name="insured_in_or_filed_bankruptcy" selections={YES_OR_NO_CHOICES} disabled />
                        <FormSelect name="insured_owing_back_taxes" selections={YES_OR_NO_CHOICES} onSubmit={submit} />
                        <BlurSubmitFormInput name="historical_wind_claims" onSubmit={submit} onReset={() => resetField('historical_wind_claims')} />
                    </DataInfoBlock>
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.PREMIUM}
                        active={activeGroups.PREMIUM}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-auto-rows-min tw-gap-1"
                        glossary={submissionGlossary}
                        readonly
                    >
                        <label>AOP Technical</label>
                        <BasicInput value={SUPPLEMENTAL_DEFINITIONS.af.cellDataGetter(undefined, { currentSubmission })} disabled />

                        <label>CAT Technical</label>
                        <BasicInput value={SUPPLEMENTAL_DEFINITIONS.cf.cellDataGetter(undefined, { currentSubmission })} disabled />

                        <label>Technical (excl Terror)</label>
                        <BasicInput value={SUPPLEMENTAL_DEFINITIONS.aact.cellDataGetter(undefined, { currentSubmission })} disabled />

                        <label>Charged (excl Terror)</label>
                        <BasicInput
                            value={SUPPLEMENTAL_DEFINITIONS.chr_pr.cellDataGetter(undefined, { currentSubmission })}
                            maskOptions={MASKS.CURRENCY_INTEGER}
                            disabled
                        />

                        <label>% of Tech</label>
                        <BasicInput value={SUPPLEMENTAL_DEFINITIONS.potec.cellDataGetter(undefined, { currentSubmission })} disabled />

                        <label>Account Rate (incl Terror)</label>
                        <BasicInput value={SUPPLEMENTAL_DEFINITIONS.r.cellDataGetter(undefined, { currentSubmission, currentQuote })} disabled />

                        <FormInput name="charged_terror_premium" />
                    </DataInfoBlock>
                    {activeGroups.CARRIER_INFORMATION && renderBlock(SUBMISSION_INFOBLOCKS.carrier_information)}
                    {activeGroups.ADDITIONAL_DETAILS && renderBlock(SUBMISSION_INFOBLOCKS.additional_details)}
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.PROPERTY_INFORMATION}
                        active={activeGroups.PROPERTY_INFORMATION}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-auto-rows-min tw-gap-1"
                        glossary={submissionGlossary}
                        readonly
                    >
                        <FormInput name="primary_quote_total_building_tiv" disabled />
                        <FormInput name="primary_quote_max_building_tiv" disabled />
                        <FormInput name="primary_quote_number_of_buildings" disabled />
                        <FormSelect name="home_state" selections={CONSTANTS.states} disabled />
                        <FormInput name="primary_quote_msb_qualified_locs_pct" percentAfter disabled />
                        <FormInput name="primary_quote_in_scope_index" percentAfter disabled />

                        {submissionGlossary?.primary_quote_data_completeness_index?.active && (
                            <>
                                <label>{submissionGlossary?.primary_quote_data_completeness_index?.label}</label>
                                <div className="tw-flex tw-gap-1">
                                    <div
                                        className={cx(
                                            'badge tw-text-white tw-max-w-[95px] tw-flex-initial tw-px-1 tw-py-1.5 tw-justify-center',
                                            BADGE_CLASSNAME_MAP[overviewData?.primary_quote_data_completeness_index_rating]
                                        )}
                                    >
                                        <span className="tw-truncate" title={dataCompletenessRating}>
                                            {dataCompletenessRating}
                                        </span>
                                    </div>
                                    <FormInput className="tw-flex-1 tw-min-w-[50px]" name="primary_quote_data_completeness_index" percentAfter disabled />
                                </div>
                            </>
                        )}

                        {submissionGlossary?.primary_quote_itv_account_quality_index?.active && (
                            <>
                                <label>{submissionGlossary?.primary_quote_itv_account_quality_index?.label}</label>
                                <div className="tw-flex tw-gap-1">
                                    <div
                                        className={cx(
                                            'badge tw-text-white tw-max-w-[95px] tw-flex-initial tw-px-1 tw-py-1.5 tw-justify-center',
                                            BADGE_CLASSNAME_MAP[overviewData?.primary_quote_itv_account_quality_index_rating]
                                        )}
                                    >
                                        <span className="tw-truncate" title={buildingValueRating}>
                                            {buildingValueRating}
                                        </span>
                                    </div>
                                    <FormInput className="tw-flex-1 tw-min-w-[50px]" name="primary_quote_itv_account_quality_index" percentAfter disabled />
                                </div>
                            </>
                        )}
                    </DataInfoBlock>
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.LIMITS_DEDUCTIBLES}
                        active={activeGroups.LIMITS_DEDUCTIBLES}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-auto-rows-min tw-gap-1"
                        glossary={submissionGlossary}
                        readonly
                    >
                        <label>Limit</label>
                        <BasicInput
                            value={
                                currentQuotePolicyCoverage && currentQuoteFinalPremium
                                    ? isLayeredRatio(currentQuotePolicyCoverage, +currentQuoteFinalPremium.tiv)
                                    : undefined
                            }
                            title={
                                currentQuotePolicyCoverage && currentQuoteFinalPremium
                                    ? isLayeredRatio(currentQuotePolicyCoverage, +currentQuoteFinalPremium.tiv)
                                    : undefined
                            }
                            disabled
                        />

                        <FormInput name="equipment_breakdown" disabled />
                        <FormInput name="primary_quote_total_bi_limit" disabled />
                        <FormInput name="primary_quote_bi_pct" percentAfter disabled />
                        <FormInput name="wdr_limit" disabled />
                        <label>Cat Deductible</label>
                        <BasicInput value={SUPPLEMENTAL_DEFINITIONS.catd.cellDataGetter(undefined, { appContext: { currentQuote } })} disabled />
                        <FormInput name="aow_deductible" disabled />
                        <FormInput name="aop_deductible" disabled />
                        <FormInput name="water_damage_deductible" disabled />
                        <FormInput name="max_flood_limit" disabled />
                    </DataInfoBlock>
                    {activeGroups.DISTRIBUTION_BY_CONST_TYPE && renderBlock(SUBMISSION_INFOBLOCKS.dist_by_construction)}
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.INSURED_INFORMATION}
                        active={activeGroups.INSURED_INFORMATION}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-auto-rows-min tw-gap-1"
                        glossary={submissionGlossary}
                        readonly={readonly}
                    >
                        <BlurSubmitFormInput name="insured_name" onSubmit={submit} onReset={() => resetField('insured_name')} />
                        <BlurSubmitFormInput name="insured_street" onSubmit={submit} onReset={() => resetField('insured_street')} />
                        <BlurSubmitFormInput name="insured_city" onSubmit={submit} onReset={() => resetField('insured_city')} />
                        <FormSelect name="insured_state" selections={CONSTANTS.states} nullLabel={CHOOSE_A_VALUE} onSubmit={submit} optional />
                        <BlurSubmitFormInput name="insured_zipcode" onSubmit={submit} onReset={() => resetField('insured_zipcode')} />
                        <FormSelect name="insured_business_form" selections={CONSTANTS.BUSINESS_FORM} onSubmit={submit} nullLabel={CHOOSE_A_VALUE} optional />
                        <BlurSubmitFormInput
                            name="insured_description_of_primary_operations"
                            onSubmit={submit}
                            onReset={() => resetField('insured_description_of_primary_operations')}
                        />
                        <FormInput name="ofac_status" disabled />
                    </DataInfoBlock>
                    {activeGroups.NOTES && renderBlock(SUBMISSION_INFOBLOCKS.general_notes)}
                    {activeGroups.RENEWAL_NOTES && renderBlock(SUBMISSION_INFOBLOCKS.general_renewal_notes)}
                    {activeGroups.DISTRIBUTION_BY_OCCUPANCY && renderBlock(SUBMISSION_INFOBLOCKS.dist_by_occupancy)}
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.INSURED_CONTACT_INFORMATION}
                        active={activeGroups.INSURED_CONTACT_INFORMATION}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-auto-rows-min tw-gap-1"
                        glossary={submissionGlossary}
                        readonly={readonly}
                    >
                        <BlurSubmitFormInput name="insured_contact_name" onSubmit={submit} onReset={() => resetField('insured_contact_name')} />
                        <BlurSubmitFormInput name="insured_contact_email" onSubmit={submit} onReset={() => resetField('insured_contact_email')} />
                        <BlurSubmitFormInput
                            name="insured_contact_phone"
                            maskOptions={MASKS['PHONE_NUMBER']}
                            onSubmit={submit}
                            onReset={() => resetField('insured_contact_phone')}
                        />
                    </DataInfoBlock>
                    {activeGroups.QUOTES && renderBlock(SUBMISSION_INFOBLOCKS.quotes)}
                    {activeGroups.ADDITIONAL_NAMED_INSUREDS && renderBlock(SUBMISSION_INFOBLOCKS.additional_name_insureds)}
                    <DataInfoBlock
                        header={OVERVIEW_GROUPS.ASSIGNED_CLAIMS_ADJUSTER}
                        active={activeGroups.ASSIGNED_CLAIMS_ADJUSTER}
                        errors={errors}
                        className="tw-grid tw-grid-cols-2 tw-auto-rows-min tw-gap-1"
                        glossary={submissionGlossary}
                    >
                        <FormSelect name="use_assigned_claims_adjuster" selections={YES_OR_NO_CHOICES} onSubmit={submit} />
                        <BlurSubmitFormInput
                            name="assigned_claims_adjuster_name"
                            disabled={!useAssignedClaimsAdjuster}
                            onSubmit={submit}
                            onReset={() => resetField('assigned_claims_adjuster_name')}
                        />
                        <BlurSubmitFormInput
                            name="assigned_claims_adjuster_title"
                            disabled={!useAssignedClaimsAdjuster}
                            onSubmit={submit}
                            onReset={() => resetField('assigned_claims_adjuster_title')}
                        />
                        <BlurSubmitFormInput
                            name="assigned_claims_adjuster_company_name"
                            disabled={!useAssignedClaimsAdjuster}
                            onSubmit={submit}
                            onReset={() => resetField('assigned_claims_adjuster_company_name')}
                        />
                        <BlurSubmitFormInput
                            name="assigned_claims_adjuster_email"
                            disabled={!useAssignedClaimsAdjuster}
                            onSubmit={submit}
                            onReset={() => resetField('assigned_claims_adjuster_email')}
                        />
                        <BlurSubmitFormInput
                            name="assigned_claims_adjuster_phone"
                            maskOptions={MASKS['PHONE_NUMBER']}
                            disabled={!useAssignedClaimsAdjuster}
                            onSubmit={submit}
                            onReset={() => resetField('assigned_claims_adjuster_phone')}
                        />
                    </DataInfoBlock>
                </FormProvider>
                {children}
            </div>
        </div>
    );
};

export default OverviewApp;
